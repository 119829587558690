import React from "react";
import { Router } from "@reach/router";

import Home from "./Home";
import Stories from "./Stories";

import "./App.css";
import "./Stories";

function App() {
  return (
    <div className="App">
      <Router>
        <Home path="/" />
        <Stories path="/stories/:sectionId" />
      </Router>
    </div>
  );
}

export default App;
