import React from "react";
import CircleLoader from "react-spinners/CircleLoader";

const Loading = () => (
  <div className="loading">
    <CircleLoader size={150} loading={true} color={"#b794f4"} />
  </div>
);

export default Loading;
