import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { FiHelpCircle } from "react-icons/fi";

const Appbar = (props) => (
  <div className="appbar">
    <div className="back">
      {props.back && (
        <button onClick={(e) => window.history.back()} className="back">
          <FaArrowLeft />
        </button>
      )}
    </div>
    <div className="title">{props.title}</div>
    <div className="actions">
      <FiHelpCircle
        className="link"
        onClick={(e) =>
          (window.location.href =
            "https://www.wcpl.net/kids-teens/dial-a-story/about-dial-a-story/")
        }
      />
    </div>
  </div>
);

export default Appbar;
