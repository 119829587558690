import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "@reach/router";

import Loading from "./Loading";
import "./App.css";
import Appbar from "./Appbar";

function Home() {
  const [appState, setAppState] = useState({
    sections: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const sectionsUrl =
      "https://content.thosegeeks.com/wcpl-dial-a-story/items/sections?fields=*.*&sort=title&filter[parent][null]";
    axios.get(sectionsUrl).then((sections) => {
      const allSections = sections.data.data;
      setAppState({ sections: allSections });
      setIsLoading(false);
    });
  }, [setAppState]);

  return (
    <div className="App">
      <Appbar
        title="Dial-a-Story Archives"
        // back={true}
      />
      {/* <h1 className="page-title">Dial-a-Story</h1> */}
      {isLoading ? (
        <Loading />
      ) : (
        <div className="sectionsWrapper">
          {appState.sections &&
            appState.sections.map((section) => {
              return (
                <Link to={`/stories/${section.id}`} key={section.id}>
                  <img src={section.image.data.full_url} alt={section.title} />
                  <h2>{section.title}</h2>
                </Link>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default Home;
