import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link } from "@reach/router";
import { FaPlay, FaPause } from "react-icons/fa";

import Loading from "./Loading";
import Appbar from "./Appbar";
import "./App.css";

function Stories(props) {
  const [appState, setAppState] = useState({
    loading: false,
    sections: [],
    stories: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSrc, setCurrentSrc] = useState("");
  const player = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    const currentSectionUrl = `https://content.thosegeeks.com/wcpl-dial-a-story/items/sections?fields=*.*&sort=title&filter[id]=${props.sectionId}`;
    const subSectionsUrl = `https://content.thosegeeks.com/wcpl-dial-a-story/items/sections?fields=*.*&sort=title&filter[parent.id]=${props.sectionId}`;
    const storiesUrl = `https://content.thosegeeks.com/wcpl-dial-a-story/items/stories?filter[sections.section_id]=${props.sectionId}&fields=id,title,audio_file.data`;
    axios
      .all([
        axios.get(subSectionsUrl),
        axios.get(storiesUrl),
        axios.get(currentSectionUrl),
      ])
      .then(
        axios.spread((...responses) => {
          const allSections = responses[0].data.data;
          const allStories = responses[1].data.data;
          const currentSection = responses[2].data.data;
          setAppState({
            loading: false,
            sections: allSections,
            stories: allStories,
            currentSection: currentSection,
          });
          setIsLoading(false);
        })
      );
  }, [setAppState, props.sectionId]);

  useEffect(() => {
    if (currentSrc) {
      player.current.play();
      setIsPlaying(true);
    }
  }, [currentSrc]);

  function handlePlay(story) {
    setCurrentSrc(story.audio_file.data.full_url);
    if (currentSrc) {
      player.current.play();
      setIsPlaying(true);
    }
  }

  const handlePause = () => {
    player.current.pause();
    setIsPlaying(false);
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="App">
          <Appbar
            title={appState.currentSection && appState.currentSection[0].title}
            back={true}
          />
          <div className="page-wrapper">
            <h1>
              {appState.currentSection && appState.currentSection[0].title}
            </h1>
            <div className="sections-tags">
              {appState.sections &&
                appState.sections.map((section) => {
                  return (
                    <Link
                      to={`/stories/${section.id}`}
                      className="storyTag"
                      key={section.id}
                    >
                      <div className="storyTagInner">
                        <img
                          src={section.image.data.full_url}
                          alt={section.title}
                        />
                        <h2>{section.title}</h2>
                      </div>
                    </Link>
                  );
                })}
            </div>
            {appState.stories &&
              appState.stories.map((story) => {
                return (
                  <div
                    className="story-item"
                    key={story.id}
                    onClick={(e) =>
                      isPlaying && story.audio_file.data.full_url === currentSrc
                        ? handlePause()
                        : handlePlay(story)
                    }
                  >
                    <div>{story.title}</div>
                    {isPlaying &&
                    story.audio_file.data.full_url === currentSrc ? (
                      <FaPause
                      // onClick={(e) => handlePause()}
                      // className="button"
                      />
                    ) : (
                      <FaPlay
                      // onClick={(e) => handlePlay(story)}
                      // className="button"
                      />
                    )}
                  </div>
                );
              })}
            <audio src={currentSrc} ref={player} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Stories;
